import styled from "styled-components";
const ForgetPasswordPageDisplay = styled.div`
  .txt {
    padding: 0 20px;
  }
  .formsLayout 
  {
    .footer
    {
      margin-bottom:30vh;
    }
  }
 
`;
export default ForgetPasswordPageDisplay;
