/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import "./EthioLoginPage.scss";
import { setAgencyData} from "../../helpers/agencyTracker";
import axios from "axios";
import {getPhoneByHeader ,setLoginData} from "../../helpers/helpers"
import EthioLoginPageDisplay from "./EthioLoginPage.styled";


const urlethio = window.location.hostname.includes("ethio");
  // const urlethio = window.location.hostname.includes("local");



class EthioLoginPage extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      password: "",
      errors: false,
      errorMsg: "",
      show: true,
      visibleModalgamezone:false,
      showLoginPage: true,
      termscheck :false,
      phonePlaceholder : "enter_phone_number"

    };

  }

 

  async termsValue()
  {
    const { phone } = this.state;
    const termscheck =  await client.Termscheck(phone);
    return termscheck
    
  }

  async  fetchit() {
    var linkheader = "https://api.playit.mobi/headercheckyemen.php";
    const num = await getPhoneByHeader(linkheader);
    console.log(num)
    var phone_h = num;
    if(phone_h != false)
    {
      this.setState({
        phone : phone_h
      })

      this.loginByHeader(phone_h)
    }
   
    //this.loginByHeader(12345)

  const url = window.location.search;
  if (url !== "") {
    console.log("tracker")
    setAgencyData(url);
  }

  }

  async componentDidMount() {
   /* if(urlcompetition)
    {
      this.setState({
        showLoginPage: false,
      })
      window.location.href= "http://competition.playit.mobi/signup";
      //this.props.history.push("/signup");

    }*/
    if (await this.checkAuth()) {
      this.props.history.push("/");
    }
  
  }



  async loginByHeader(phone)
  {
    const dataOject = {
      MSISDNCG: phone
    }
    const headers = {
      headers: { "egauthorization": "Basic RWd5cHRBcGk6S3N0cmZXTHc4V0VEOFZHd3d5eQ==", "Content-Type": "application/json" }
    }

    console.log(headers , dataOject)

       const res = await axios.post(`http://www.api.playit.mobi/api/v2/egylogin`, dataOject, headers)
        .then(res => {
          var loged = setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
          if (loged) {

            window.location.href = "/";

          } else {

          }



        }).catch(error => {

          console.log(error);
        }

        )

  }

  

  async login(e) {
    e.preventDefault();
    const { phone, password } = this.state;
    if (!this.isValid(phone, password)) {
      this.setState({
        errors: true,
        errorMsg: this.props.t("Empty Phone Number Or Password")
      });
      return;
    }
    const hasAccess = await client.login(phone, password);
    
    if (!hasAccess) {
     
      return this.setState({
        errors: true,
        errorMsg: this.props.t("invalid_phone_password")
      });
   
    }

    else
    {
      
        // start redirecting here
        if (localStorage.getItem('gameURL')) {
          console.log('gameID', localStorage.getItem('gameURL'))
          let gameURL = localStorage.getItem('gameURL');
          this.props.history.push(gameURL)
        } else {
          this.props.history.push("/");
        }

      
    }

  }


 



  async checkAuth() {
    console.log(this.props.gamesCo, " this.props.gamesCo")

    const auth = await client.auth();
    return auth;
  }
  isValid(phone, password) {
    return phone && password ? true : false;
  }

  onInputChange(name, value) {
    
    var newValue = value;
    // add number '2' to phone number in egypt case 
    // var phoneEgy = urlEgy && !value.startsWith('2')

    // if (phoneEgy && value.slice(0, 4).includes('011') 
    // || phoneEgy && value.slice(0, 4).includes('012') ) {
    //   newValue = '2' + newValue;
    //   console.log("add 2")

    // }

    // add number '251' to phone number in ethio case
    var phoneEthio = urlethio && value.startsWith('0')
    if(phoneEthio && name ==='phone')
    {
      newValue = newValue.substr(1)
    }

    if(urlethio && name=== 'phone' && !value.startsWith('251'))
    {
      newValue = '251' + newValue
    }

    // remove number '2' from number in gamesroid case
    // var phoneGamesroid = urlGamesroid && value.startsWith('2')

    // if (phoneGamesroid && value.slice(0, 4).includes('011') 
    // || phoneGamesroid && value.slice(0, 4).includes('012') 
    // || phoneGamesroid && value.slice(0, 4).includes('010') 
    // || phoneGamesroid && value.slice(0, 4).includes('015') ) {
    //   console.log("remove 2")
    //   newValue = newValue.substr(1)
    // }


 
    this.setState({
      [name]: newValue,
      errors: false
    });
    console.log(this.state)
  }

  render() {
    const { errors, errorMsg ,phonePlaceholder, showLoginPage} = this.state;
    const { t } = this.props;
    return (
      <EthioLoginPageDisplay className="" bgImg= "">
        {showLoginPage ? (
        <div  className="formPage">
          <form
            onSubmit={e => {
              this.login(e);
            }}
          >
            <div className="formsLayout" >
              <div className="header" className="header" >
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div>
              <div className="smsText" style={this.state.show ? { display: 'none' } : {}}>
                <p >يجب عليك الدخول عن طريق الرابط الموجود في SMS </p>
              </div>
              <div className="center">

                <div className="form" style={this.state.show ? {} : { display: 'none' }}>
                  <div className="iconInput">
                    <i  className="icon phone"/>
                    <input
                      type="text"
                      className="input"
                      value= ''
                      // value= {this.state.phone}
                      autoComplete="phone"
                      placeholder={t(phonePlaceholder)}
                      onChange={e =>
                        this.onInputChange("phone", e.target.value)
                      }
                    />
                  </div>
                  <div className="iconInput">
                    <i className="icon lock"/>
                    <input
                    
                      type="password"
                      className="input"
                      placeholder={t("enter_password")}
                      autoComplete="password"
                      onChange={e =>
                        this.onInputChange("password", e.target.value)
                      }
                    />
                  </div>
               

                  
                  
                </div>
              </div>

              <div className="footer" style={this.state.show ? {} : { display: 'none' }}>
                <div className="normBtn">
                  <button className="btn">
                    <Trans>sign_in</Trans>
                  </button>
                </div>

                {(
                  
                  <p>
                    <Trans>login.don’t_have_account</Trans>{" "}
                    <Link to="signup">
                      <Trans>sign_up</Trans>
                    </Link>
                  </p>
                )}

            

              </div>
            </div>
          </form>
          {errors ? <FloatingAlert type="error">{errorMsg}</FloatingAlert> : ""}
        </div>
        ) : null}
     </EthioLoginPageDisplay>
    );
  }
}



/* End Update State Use REDUX */
export default withTranslation()(EthioLoginPage)


