import axios from "axios";
import envo from "../environments/environmentProd";
const apiUrl = envo.apiUrl;
var urlGamezone = window.location.href.includes("ao.playit");
var urlYemen = window.location.href.includes("gamesstore");
var urlyMobile = window.location.href.includes("ymobile");


async function forgetPassword(phoneNum) {
    let params = { phone: phoneNum };
    try {
        var urlforget = `${apiUrl}/playit/password/forget`;
        if (urlGamezone) {
            urlforget = `http://api.playit.mobi/api/v2/Unitel/forgetpass`;
        }
        if (urlYemen) {
            urlforget = `${apiUrl}/mtnyemn/resetPassword`;
        }

        if (urlyMobile) {
            urlforget = `${apiUrl}/yemen-mobile/forgot-password`;
            if (!phoneNum.slice(0, 4).includes('967')) {
                phoneNum = '967' + phoneNum;
              }
            params = {msisdn: phoneNum}
        }
        const res = await axios.get(urlforget, { params });
        if (!res.data) return false;
        const data = res.data;
        if (res.status !== 200) {}
        return data;
    } catch (err) {
        if (err.response && err.response.status === 404) {
            return 404;
        }
        return false;
    }
}
export default forgetPassword;