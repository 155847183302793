import queryString from "query-string";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import UnitelSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { getPhoneByHeader, getRedirectUrl, subscribe } from "./services";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import "./UnitelSignupPage.scss";
import { useHistory } from "react-router";

const randomBg = bgSliders.getRandomBg();
function UnitelSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [checkPhone_h, setCheckPhone_h] = useState(false);


  const [encryptedPhone, setEncryptedPhone] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [disableverifyCode, setDisableverifyCode] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [isEncrypted, setIsEncrypted] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [signup, setSignup] = useState("signup");
  const [internetStatus, setInternetStatus] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(true);
  const urljawwal = window.location.hostname.includes("jawwal");
  const url = window.location.href;
  const history = useHistory();
  const ref = useRef(null);

  const APImsisdn = {
    bhmsisdn: "",
  };

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (url.includes("signup2")) {
      setSignup("signup2");
    }

    setTermsAndConditions(true);

    async function fetchit() {
      var linkheader = "./checkheaderunitel.php";

      // var linkheader = 'http://ao.playit.mobi/checkheaderunitel.php'

      for (let index = 0; index < 3; index++) {
        // setTimeout(async () => {
        const num = await getPhoneByHeader(linkheader);
        var phone_h = num;
        console.log("phone", phone_h);
        setCheckPhone_h(phone_h)
        setActiveData(phone_h);
        setEncryptedPhone(phone_h);
        console.log('in')

        // }, 3000);

      }

      console.log('out')

      console.log('checkPhone_h', phone_h)
      if (phone_h != false) {
        console.log('task true')
        const decryptMsisdUrl =
          "http://api.playit.mobi/api/v2/Unitel/decryptMsisdnunitel";
        var senddata = {
          phone: phone_h,
        };
        try {
          const res = await axios.post(decryptMsisdUrl, senddata);
          if (res.data) {
            const data = res.data;
            if (data.status == true) {
              setActiveData(data.msisdn);
            }
          }
        } catch (err) {
          console.log(err);
        }

        setCheckPhone(true);
      } else {
        // setInternetStatus('')
        // console.log('task false')
        setTimeout(() => {
          setInternetStatus(
            localStorage.getItem("i18nextLng") == "en"
              ? "Dear customer, to subscribe to the portal and have access to the contents, you must turn on your mobile data. Thanks!"
              : "Caro cliente, para subscrever ao portal e ter acesso aos conteudos, deves ligar os dados móveis. Obrigado!"
          );

        }, 5000);



      }
    }
    const urlsearch = window.location.search;
    if (urlsearch !== "") {
      console.log("tracker");
      setAgencyData(urlsearch);
    }

    fetchit();
  }, []);

  // save tracker
  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem("tracker")) {
      var op_id = "467x6F5iHl";

      datag = {
        key: "parameter",
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem("tracker"),
        identifierKey: "id",
        identifierValue: op_id,
        phoneNum: GMSISDN,
      };
      console.log(datag);
    }
    await saveTracker(datag);
  };

  const Subscribe = async (e) => {
    e.preventDefault();
    setshowLoading(true);
    if (activeData) {

      if (checkPhone == true) {
        var phon_n = encryptedPhone.toString();
      } else {
        var phon_n = activeData.toString();
        if (!phon_n.slice(0, 4).includes("244")) {
          phon_n = "244" + phon_n;
        }
      }
      console.log("activeData", activeData);
      console.log("encryptedPhone", encryptedPhone);
      var url = "";
      /*if (!phon_n.slice(0, 4).includes('244')) {   
        phon_n = '244' + phon_n;
      }*/

      if (checkPhone == true) {
        url = `http://api.playit.mobi/api/v2/Unitel/subscribe?msisdn=${phon_n}&headerenrich=yes`;

        var company_value = localStorage.getItem("trackerCompany");
        var tracker_value = localStorage.getItem("tracker");
        if (tracker_value != "") {
          url = `http://api.playit.mobi/api/v2/Unitel/subscribe?msisdn=${phon_n}&headerenrich=yes&company_value=${company_value}&tracker_value=${tracker_value}`;
        }
        axios
          .get(url)
          .then((res) => {
            console.log(res);
            if (res.data.status == true) {
              if (res.data.userId) {
                tracker(phon_n);
                setLoginData(
                  res.data.loginData.user_id,
                  res.data.loginData.token,
                  res.data.loginData.user_name,
                  res.data.loginData.photo,
                  res.data.loginData.operator_id,
                  res.data.loginData.user_email,
                  res.data.loginData.msisdn
                );
                setTimeout(() => {
                  window.location.href = "/";
                }, 5000);
                //history.push('/thank-gamezone');
              } else {
                setTimeout(() => {
                  window.location.href = "/";
                }, 5000);
                //history.push('/thank-gamezone');
              }
            } else {
              setErrorMesgSubscribe(res.data.message);
              setErrorMesg(true);
              //window.location.href ="/login"
            }

            setshowLoading(false);
          })
          .catch(function (error) {
            setErrorMesg(true);
            setShowverif(false);
            setshowLoading(false);
          })
          .finally(function () {
            console.log("complete");
          });
      } else {
        url = `http://api.playit.mobi/api/v2/Unitel/subscribe?msisdn=${phon_n}&headerenrich=no`;
        axios
          .get(url)
          .then((res) => {
            console.log(res.data.status);
            if (res.data.status == true) {
              setShowverif(true);
              setErrorMesg(false);
            } else {
              setErrorMesgSubscribe(res.data.message);
              setErrorMesg(true);
              //setShowverif(true);
              //window.location.href = "/login"
            }
            setshowLoading(false);
          })
          .catch(function (error) {
            setErrorMesg(true);
            setShowverif(false);
            setshowLoading(false);
          })
          .finally(function () {
            console.log("complete");
          });
      }


    }

  };
  const verify = async (e) => {
    e.preventDefault();
    if (verifyCode) {
      setshowLoading(true);
      
      if (checkPhone == true) {
        var phon_n = encryptedPhone.toString();
      } else {
        var phon_n = activeData.toString();
        if (!phon_n.slice(0, 4).includes("244")) {
          phon_n = "244" + phon_n;
        }
      }
      /*if (!phon_n.slice(0, 4).includes('244')) {
        phon_n = '244' + phon_n;
      }*/
      var verify_url = "";

      verify_url = `http://api.playit.mobi/api/v2/Unitel/verify?msisdn=${phon_n}&pin=${verifyCode}`;

      var company_value = localStorage.getItem("trackerCompany");
      var tracker_value = localStorage.getItem("tracker");
      console.log(tracker_value);
      if (tracker_value) {
        console.log("tracker");
        verify_url = `http://api.playit.mobi/api/v2/Unitel/verify?msisdn=${phon_n}&pin=${verifyCode}&company_value=${company_value}&tracker_value=${tracker_value}`;
      }

      axios
        .get(verify_url)
        .then((res) => {
          if (res.data.status == true) {
            setVerfiErrorMesg(false);
            if (res.data.userId) {
              tracker(phon_n);
              setLoginData(
                res.data.loginData.user_id,
                res.data.loginData.token,
                res.data.loginData.user_name,
                res.data.loginData.photo,
                res.data.loginData.operator_id,
                res.data.loginData.user_email,
                res.data.loginData.msisdn
              );
              setTimeout(() => {
                window.location.href = "/";
              }, 5000);
              //history.push('/thank-gamezone');
            } else {
              //history.push('/thank-gamezone');

              setTimeout(() => {
                window.location.href = "/";
              }, 5000);
            }
            setshowLoading(false);

          } else {
            setshowLoading(false);
            setVerfiErrorMesg(true);
          }
        })
        .catch(function (error) {
          console.log(error);
          setVerfiErrorMesg(true);
          setshowLoading(false);

        })
        .finally(function () {
          console.log("complete");
        });
    }
  };

  const termsAgree = async (e) => {
    console.log(e);
    console.log(ref.current.checked);
    setTermsAndConditions(ref.current.checked);
  };

  return (
    <UnitelSignupPageDisplay
      className=""
      bgImg={signup == "signup" ? "" : randomBg}
    >
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {/* {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null} */}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.VERIFY_ERROR</Trans>
        </FloatingAlert>
      ) : null}

      {showLoading ? (
        <div className="load">
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
      ) : null}

      <div
        className={
          signup == "signup2"
            ? "operatorSignup"
            : "operatorSignup gamezoneSignup"
        }
      >

        {!internetStatus ? (
          <div className="formsLayout">
            <div
              className={
                signup == "signup2" ? "header" : "header header_gamezone"
              }
            >
              <Link to="/">
                <div
                  className={signup == "signup2" ? "logo" : "logo gamezoneLogo"}
                />
              </Link>
            </div>

            {!showverif && signup == "signup2" ? (
              <div className="center">
                <form>
                  <div>
                  <p>{activeData ? activeData : " "}</p>
                  {/*<input
                      type="text"
                      className="input"
                      placeholder={t("Enter_your_phone_number")}
                      value={activeData ? activeData : ""}
                      // disabled={checkPhone == true}
                      onChange={(e) =>
                        setActiveData(e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />*/ }
                    <br></br>
                    <br></br> <br></br>
                    <button
                      className="btn"
                      disabled={
                        activeData &&
                          termsAndConditions == true
                          ? ""
                          : "disabled"
                      }
                      // disabled={activeData ? "" : "disabled"}
                      onClick={(e) => {
                        Subscribe(e);
                      }}
                    >
                      {t("Subscribe")}
                    </button>
                  </div>
                </form>
              </div>
            ) : null}

            {!showverif && signup == "signup" ? (
              <div className="links-Section links-SectionGamezone">
                <h3
                  style={{ paddingBottom: "1px" }}
                  className="gamezoridcontent"
                  id="gamezoridcon"
                >
                  <Trans>Your content is ready!</Trans>
                </h3>
                <div className="play"></div>

                <p>{activeData ? activeData : " "}</p>


               {/*} <input
                  type="text"
                  className="input gamezoneInput"
                  placeholder={t("Enter_your_phone_number")}
                  value={activeData ? activeData : ""}
                  // disabled={checkPhone == true || !encryptedPhone}
                  onChange={(e) =>
                    setActiveData(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />*/}



                <div>
                  <button
                    className="btn btngamezone"
                    disabled={
                      activeData &&
                        termsAndConditions == true
                        ? ""
                        : "disabled"
                    }
                    onClick={(e) => {
                      Subscribe(e);
                    }}
                  >
                    {t("Subscribe")}
                  </button>
                </div>

                <span>
                  220 Kzs/Semana
                  <br />
                  Será subscrito ao portal PlayIT por 220 Kzs
                  renováveis
                </span>

                <div className="footer">
                  {!showverif ? <div></div> : null}
                  <div className="ncellTos">
                    <h3>
                      <input
                        type="checkbox"
                        defaultChecked={true}
                        ref={ref}
                        className="inputcheck"
                        onChange={termsAgree}
                        id="agree"
                      />
                      {termsAndConditions == true ? (
                        <a
                          href="http://ao.playit.mobi/tos"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: "white",
                            cursor: "pointer",
                            fontSize: "24px",
                          }}
                        >
                          <Trans>terms_and_conditions</Trans>
                        </a>
                      ) : (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: "white",
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                        >
                          <Trans>terms_and_conditions</Trans>
                        </a>
                      )}
                    </h3>
                  </div>
                </div>

                <div className="row text-center">
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>98%</span>
                      <img
                        src="/assets/images/subscribegamezriod/like.png"
                        alt="img"
                        className="iconsimg"
                      />
                      <p>
                        <Trans>evaluation</Trans>
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>1000+</span>
                      <img
                        src="/assets/images/subscribegamezriod/Repeat.png"
                        alt="img"
                        className="iconsimg"
                      />
                      <p>
                        <Trans>game</Trans>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>500k+</span>
                      <img
                        src="/assets/images/subscribegamezriod/group.png"
                        alt="img"
                        className="iconsimg"
                      />
                      <p>
                        <Trans>user</Trans>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {showverif ? (
              <div className="verfiySection">
                <p>
                  <Trans>verifycode</Trans>
                </p>
                <input
                  type="text"
                  className="input"
                  // disabled={disableverifyCode === true }

                  // value={verifyCode}
                  placeholder={t("Enter_verifycode")}
                  onChange={(e) =>
                    setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                <br></br>
                <br></br> <br></br>
                <button
                  className="btn btngamezone"
                  disabled={verifyCode ? "" : "disabled"}
                  onClick={(e) => {
                    verify(e);
                  }}
                >
                  {t("Verify")}
                </button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="internet-issue">
            <span>{internetStatus}</span>
          </div>
        )}
        {errorMesg ? (
          <FloatingAlert type={"error"}>
            <Trans>{errorMesgSubscribe}</Trans>
          </FloatingAlert>
        ) : null}
      </div>
    </UnitelSignupPageDisplay>
  );
}
export default withTranslation()(UnitelSignupPage);
