import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import h from "../../helpers/helpers";
import SingleGame from "./../../components/SingleGame/SingleGame";
import "./GameSlider.scss";
import GameSliderDisplay from "./GameSlider.styled";
class GameSlider extends Component {
  render() {

    const hostName = window.location.hostname;
    // const category = 'category_yemen';
    const category = h.getCategoryByHostName(hostName);
    const urlooredoo = window.location.hostname.includes("ooredoo");
    const urlethio = window.location.hostname.includes("ethio");
    const urlorange = window.location.hostname.includes("orange");
    const urlzainjordan = window.location.hostname.includes("zainjordan");
    const urlgamezroid = window.location.hostname.includes("gamezroid");
    const urlgamezroidRamadank = window.location.hostname.includes("ramdank");
    const urlstckwuit = window.location.hostname.includes("stckuwait");
    const urlgamezone= window.location.hostname.includes("ao.playit");
    const urlsabafon= window.location.hostname.includes("sabafon");
    const urlafricell= window.location.hostname.includes("africell");
    const urlksa= window.location.hostname.includes("ksa");
    const urlumniah= window.location.hostname.includes("umniah");
    const urlEgy = (window.location.hostname.includes("egypt") && !window.location.hostname.includes("orange"));
    const urlDemo= window.location.hostname.includes("demo");
    const urlVodafone = window.location.hostname.includes("vodafoneegypt");
    const urlcompetition = window.location.hostname.includes("competition");
    const urlOmantel = window.location.hostname.includes("omantel");
    const urlmtnng = window.location.hostname.includes("mtnng");
    const urlncell = window.location.hostname.includes("ncell");
    const urlMtn = window.location.hostname.includes("gamesstore");
    const urlAlgeriaor = window.location.hostname.includes("dzo");
    const urlDu = window.location.hostname.includes("du");
    const urlkorekiq = window.location.hostname.includes("korekiq");
    const urlmauritania = window.location.hostname.includes("mauritania");
    const zainbh = window.location.hostname.includes("zainbh");

    const urltelkomsel = window.location.hostname.includes("telkomsel");
    const urlymobile = window.location.hostname.includes("ymobile");




    

    const { games, name_ar, name_fr, name_pr, slug } = this.props;
    let {name} = this.props;

    ////console.log("games" , games)

  //  if(urlncell || urlOmantel || urlcompetition ||urlDu || urlkorekiq || urltelkomsel||urlmauritania||urlAlgeriaor || urlgamezroid || urlgamezroidRamadank || urlMtn ||urlethio || urlDemo|| urlumniah || urlVodafone || urlksa || urlooredoo || urlstckwuit || urlzainjordan || urlgamezone || urlsabafon || urlafricell || urlEgy || urlorange || urlmtnng || zainbh)
  //  {
    switch(name)
    {
      case 'action':
        name = 'Action'
        break;
  
      case 'Action':
          name = 'Action'
          break;
    
        case 'sports':
          name = 'Sports'
          break;
  
        case 'kids and education':
          name = 'Kids and Education'
          break;
         
      case 'arcade':
         name = 'Arcade'
      break;

      case 'adventure':
         name = 'Adventure'
      break;
          
      
        case 'casual':
          name = 'casual'
          break;
  
          case 'strategy':
            name = 'Strategy'
            break;
  
        case 'brain':
            name = 'Brain'
            break;
                 
        case 'multiplayer':
          name = 'multiplayer'
          break;

        case 'racing':
            name = 'Racing'
            break;  

     case 'virtual reality':
              name = 'Virtual Reality'
        break;


        default:
          break;
    }
  // }
    
   // const { games, name, name_ar, name_fr, name_pr, slug } = this.props;
    ////console.log(name);
    // //console.log(name_ar);
    // //console.log(name_fr);
    // //console.log(name_pr);
    const sliderName = h.getNameByLang( name, name_ar, name_fr, name_pr );
    // //console.log('name' , name , typeof(name))

    // //console.log('slider' , sliderName , typeof(sliderName))
    // //console.log('games' , games)
    const gamesr = games.map((i, k) => {
      if(i.worldCub === true){
        
        return (
          <SingleGame
            key={`games-${k}`}
            img={i.game_thumb}
            name={i.game_name}
            namrAr={i.game_name_ar}
            gameID={i._id}
          ></SingleGame>
        );
      }
     
    });
    return (
      
      <GameSliderDisplay>
        <div className="info">
          <div className="title">
            <img src={"/assets/icons/themes/" + category + "/" + name + ".svg"} alt={name} />
            {sliderName}
          </div>
          <div
            className="more"
            onClick={() => {
              this.props.setActiveCategory(slug);
            }}
          >
            <Trans>see_all</Trans>
          </div>
        </div>
        <div className="games">{gamesr}</div>
      </GameSliderDisplay>
    );
  }
}
export default withTranslation()(GameSlider);
