import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import { withTranslation } from "react-i18next";
import { Loader } from "../../components";
import envo from "../../environments/environmentProd";
import h from "../../helpers/helpers";
import { checkdouble, setLoginData ,getPhoneByHeader } from "../../helpers/helpers"
import { CategoryGames, FilteredGames, MainCategorySliders, MainExclusiveGame, MainTab, SubTab, SliderCompation, Competition } from "./components";
import Modal from 'react-awesome-modal';
import "./HomePage.scss";
import HomePageDisplay from "./HomePage.styled";
import getHomeGames from "./loadingData";
import getHomeGamesSlider from "./loadingSliderData";
import { setAgencyData} from "../../helpers/agencyTracker";
import { client } from "../../auth/Client";


import { checkAuth } from "../../components/SideNav/SideNav"


import queryString from "query-string";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

if (localStorage.getItem('x') === '1') {
} else {
  localStorage.setItem('x', 1);
  window.location.reload(true);
}

const urlcompetition = window.location.hostname.includes("competition");
const globalurl = window.location.href
// const urlgamezroid = window.location.href.includes('ramdank')
const urlgamezroid = window.location.href.includes('ramdan') && !globalurl.includes('asiacell') && !globalurl.includes('jawwal') && !globalurl.includes('zainiq')
//  const urlgamezroid = window.location.href.includes('local')
const urlasiacell = window.location.hostname.includes("asiacell");//asiacell
let mainTabsInfo = [];

function getMobileOperatingSystem() {
  //console.log("hello");
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

var platform = getMobileOperatingSystem();
//console.log(platform);
//platform = "iOS";
// if (urlasiacell) {
//   mainTabsInfo = [
//     { slug: "online", name: "play_online" }
//   ];
  
//   const obj = {
//     main: "online",
//     sub: "",
//     category: ""
//   };
//   localStorage.setItem("activeLocally", JSON.stringify(obj))
// }else{
  mainTabsInfo = [
    { slug: "android", name: "the_store" },
    { slug: "online", name: "play_online" }
  ];
  
  const obj = {
    main: "android",
    sub: "",
    category: ""
  };
  localStorage.setItem("activeLocally", JSON.stringify(obj))
  
// }




const urlksa2 = window.location.hostname.includes("ksa2");
const urlksa = window.location.hostname.includes("ksa");

const urlzain = window.location.hostname.includes("zain-iq2");
const urlEgy = window.location.hostname.includes("egy");
const urlgamezone = window.location.hostname.includes("ao.playit");
const urlethiogamezone = window.location.hostname.includes("ao.playit") && window.location.hostname.includes("ethio");

const globalUrl = window.location.hostname;
const urlmtnng = window.location.hostname.includes("mtnng");

const urlYmn = window.location.hostname.includes("gamesstore");
const urlUnitel = window.location.hostname.includes("ao.playit");
const urlyMobile = window.location.hostname.includes("ymobile");


//mtnng
// const obj = {
//   main: "online",
//   sub: "",
//   category: ""
// };
// localStorage.setItem("activeLocally", JSON.stringify(obj))


// if (urlmtnng) {
//   //console.log('done')
//   //loginmtnng();

// }





if (urlgamezone || urlethiogamezone || urlyMobile) {
  mainTabsInfo = [
    { slug: "online", name: "play_online" },
    { slug: "android", name: "the_store" }
  ];
  const obj = {
    main: "online",
    sub: "",
    category: ""
  };
  localStorage.setItem("activeLocally", JSON.stringify(obj))
}




if (urlksa2 || urlzain) {
  mainTabsInfo.splice(0, 1);
  const obj = {
    main: "online",
    sub: "",
    category: ""
  };
  localStorage.setItem("activeLocally", JSON.stringify(obj))
}

const curr_search = window.location.search;
const data = queryString.parse(curr_search);


const localLang = window.localStorage.getItem(`i18nextLng`);
// document.body.dir = localLang === "en" ? "ltr" : "rtl";

if (localLang === "en" || localLang === "pt" || localLang === "fr") {
  document.body.dir = "ltr"
}

const getActiveLocally = () => {
  const locally = localStorage.getItem(`activeLocally`);
  if (urlgamezone || urlcompetition) {
    return locally
      ? JSON.parse(locally)
      : {
        main: "online",
        sub: "",
        category: ""
      }
  }
  else {
    return locally
      ? JSON.parse(locally)
      : {
        main: "android",
        sub: "",
        category: ""
      }
  }

};
const setActiveLocally = obj => {
  const str = JSON.stringify(obj);
  localStorage.setItem("activeLocally", str);
};

function HomePage() {

  const [visible, setVisible] = useState(false);
  const [eguVisible, setEguVisible] = useState(true);
  const [instVisible, setInstVisible] = useState(false);
  const initActive = { ...getActiveLocally() };
  const initLoading = { type: "home", status: true };
  const [active, setActive] = useState({ ...initActive });
  const [homeGames, setHomeGames] = useState({});
  const [homeGamesSlider, setHomeGamesSlider] = useState({});

  const [activeSliders, setActiveSliders] = useState([]);
  const [activeExclusiveSliders, setActiveExclusiveSliders] = useState([]);

  const [subTabs, setSubTabs] = useState([]);
  const [subTabGames, setSubTabGames] = useState([]);
  const [loading, setLoading] = useState({ ...initLoading });
  const [categoryGames, setCategoryGames] = useState([]);
  const [showexclusive, setshowexclusive] = useState(false);
  const [showslider, setshowslider] = useState(false);
  const [showDouble, setshowDouble] = useState(false);
  const [loading2, setLoading2] = useState(false);




  const handle = useFullScreenHandle();
  const [activeCategoryName, setactiveCategoryName] = useState({
    ar: "",
    en: "",
    fr: "",
    pt: ""
  });

  async function checkDouble() {

    const check = await checkdouble();
    if (check) {
      setshowDouble(true);
    }

    localStorage.setItem('showdouble', check)


  }

  const initHome = async (mainTab, games) => {


    setActiveSliders(games[mainTab]["sliders"]);
    setActiveExclusiveSliders(games[mainTab]["exclusive"])
    if (activeExclusiveSliders.name != '') {
      setshowexclusive(true)
    }

    ////console.log('ex', activeExclusiveSliders.name)
    const subs = getSubTabs(games[mainTab].categories);
    setSubTabs([...subs]);
    // //console.log("game n", subTabs)
    if (active.sub) {
      const subGames = games[mainTab]["categories"][active.sub];
      setSubTabGames([...subGames]);
    } else if (active.category) {
      const target = games[mainTab]["sliders"].find(
        i => i.slug === active.category
      );
      setactiveCategoryName({
        ar: target.name_ar,
        en: target.name,
        fr: target.name_fr,
        pt: target.name_pr
      });
      const catGames = await getCategoryGames(target.slug, active.main);
      setCategoryGames([...catGames]);
    }

    slowlyUnLoad();
    const url = window.location.href
    if (localStorage.getItem('Modal') === '1') {
    } else if (url.includes('ethio') && data.camp === "true") {
      setTimeout(localStorage.setItem('Modal', 1), 300);
      setTimeout(() => openModal(), 2000)
    }
    // show ads after 6 secound from int home page
  };
  // model open and close functions

  const openModal = () => { setTimeout(() => (setVisible(true), 1000)) };
  const closeModal = () => { setVisible(false); setEguVisible(false) };

  async function loginmtnng() {
    const transactionId = queryString.parse(window.location.search);
    if (transactionId.trxId) {
      try {
        const url = "http://api.playit.mobi/api/v2/nigeria/loginByTrx";
        const options = {
          transactionId: transactionId.trxId
        };
        const res = await axios.post(url, options);
        if (res.data) {
          const data = res.data.data;
          if (res.data.status == true) {
            if (data.user_id) {
              setLoginData(data.user_id, data.token, data.user_name, data.photo, data.operator_id, data.user_email, data.msisdn, data.subscriptionContractId);
              //window.location.reload();
              setLoading2(true)
              // checkAuth();
            }
          }
        }
      }
      catch (err) {
        //console.log(err);

      }
    }
  }


  async function fetchYmn() {
    var linkheader = "./headercheckyemen.php";
    const num = await getPhoneByHeader(linkheader);
    var phone_h = num;
    if(phone_h != false)
    {
      var userid = localStorage.getItem("userId");
      if(userid ==""){
        loginYmnByHeader(phone_h);
      }

    }
   
    //loginYmnByHeader(123)
  
  const url = window.location.search;
  if (url !== "") {
    //console.log("tracker")
    setAgencyData(url);
  }
  
  }

  async function loginYmnByHeader(phone)
  {
    const dataOject = {
      MSISDNCG: phone
    }
    const headers = {
      headers: { "egauthorization": "Basic RWd5cHRBcGk6S3N0cmZXTHc4V0VEOFZHd3d5eQ==", "Content-Type": "application/json" }
    }

    //console.log(headers , dataOject)

       const res = await axios.post(`http://www.api.playit.mobi/api/v2/egylogin`, dataOject, headers)
        .then(res => {
          //console.log(res)
          //console.log(res.data.status)
return
          if(res.data.status){
            var loged = setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            if (loged) {
            window.location.href = "/";  
            } else {
  
            }
          }

        }).catch(error => {

          //console.log(error);
        }

        )

  }
  

  async function fetchUnitel() {
    var linkheader = "http://ao.playit.mobi/checkheaderunitel.php";
    const num = await getPhoneByHeader(linkheader);
    var phone_h = num;
    if(phone_h != false)
    {
      //loginUnitelByHeader(phone_h);
      const auth = await client.auth();
      //console.log(auth);
      if(!auth){
        loginUnitelByHeader(phone_h);
      }
      // var userid = localStorage.getItem("userId");
      // //console.log(userid);
      // if(userid =="" || userid === null){
      //   loginUnitelByHeader(phone_h);
      // }

    }
   
    //loginYmnByHeader(123)
  
  // const url = window.location.search;
  // if (url !== "") {
  //   //console.log("tracker")
  //   setAgencyData(url);
  // }
  
  }

  async function loginUnitelByHeader(phone)
  {
    const dataOject = {
      MSISDNCG: phone
    }
    const headers = {
      headers: { "egauthorization": "Basic RWd5cHRBcGk6S3N0cmZXTHc4V0VEOFZHd3d5eQ==", "Content-Type": "application/json" }
    }

    //console.log(headers , dataOject)

       const res = await axios.post(`http://www.api.playit.mobi/api/v2/egylogin`, dataOject, headers)
        .then(res => {
          //console.log(res)
          //console.log(res.data.status)
          if(res.data.status){
            var loged = setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            if (loged) {
            window.location.href = "/";  
            } else {
  
            }
          }

        }).catch(error => {

          //console.log(error);
        }

        )

  }
  


  useEffect(() => {
    if (urlmtnng) {
       loginmtnng();
    }

    if (urlYmn) {
      fetchYmn();
   }
   if (urlUnitel) {
    fetchUnitel();
 }

    //checkDouble();
    async function init() {
      const res = await getHomeGames();
      const resSlider = await getHomeGamesSlider();
      ////console.log("slider", resSlider)

      setHomeGames(res);
      // //console.log("home games", res)

      setHomeGamesSlider(resSlider.slide_games);
      initHome(active.main, res);
    }
    init();

  }, []);


  // useEffect(() => {
  //   if (loading2 == true) {
  //     window.location.reload();
  //     setLoading2(false)
  //   } else {
  //     //console.log('page already loaded');
  //   }
  // }, [loading2]);

  const handleMainTabChange = slug => {
    setLoading({ type: "home", status: true });
    const obj = {
      main: slug,
      sub: "",
      category: ""
    };
    setActive(prev => ({ ...prev, ...obj }));
    setActiveLocally({
      ...obj
    });
    initHome(slug, homeGames);
  };


  const handleSubTabChange = slug => {
    setLoading({ type: "gamesListing", status: true });
    setActive(prev => ({ ...prev, sub: slug, category: "" }));
    setActiveLocally({
      main: active.main,
      sub: slug,
      category: ""
    });
    const games = homeGames[active.main]["categories"][slug];
    setSubTabGames([...games]);
    slowlyUnLoad();
  };
  const handleCategoryChange = async slug => {
    // next 2 lines for category multilanguage name    
    const target = homeGames[active.main]["sliders"].find(i => i.slug === slug);
    setactiveCategoryName({
      ar: target.name_ar,
      en: target.name,
      fr: target.name_fr,
      pt: target.name_pr
    });
    setLoading({ type: "gamesListing", status: true });
    setActive(prev => ({ ...prev, sub: "", category: slug }));
    setActiveLocally({
      main: active.main,
      sub: "",
      category: slug
    });


    const games = await getCategoryGames(slug, active.main);

    setCategoryGames([...games]);
    setLoading(prev => ({ ...prev, status: false }));
  };

  const handleCategoryChangeex = async slug => {
    // next 2 lines for category multilanguage name    
    const target = homeGames[active.main]["exclusive"].find(i => i.slug === slug);
    setactiveCategoryName({
      ar: target.name_ar,
      en: target.name,
      fr: target.name_fr,
      pt: target.name_pr
    });
    setLoading({ type: "gamesListing", status: true });
    setActive(prev => ({ ...prev, sub: "", category: slug }));
    setActiveLocally({
      main: active.main,
      sub: "",
      category: slug
    });


    const games = await getCategoryGames(slug, active.main);

    setCategoryGames([...games]);
    setLoading(prev => ({ ...prev, status: false }));
  };


  const slowlyUnLoad = () => {
    setTimeout(() => setLoading(prev => ({ ...prev, status: false })), 200);

  };

  


  // instructions model  open and close functions
  const instCloseModal = () => { setInstVisible(false) };
  if (urlksa2) {
    var lastShownTs = +localStorage.getItem("lastShown");
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    var lastShown = null;
    if (!isNaN(lastShownTs)) {
      lastShown = new Date(lastShownTs);
      lastShown.setHours(0, 0, 0, 0);
    }
    if (lastShown == null || lastShown.getTime() != currentDate.getTime()) {

      setTimeout(() => {
        setInstVisible(true);
        localStorage.setItem("lastShown", currentDate.getTime());
      }, 1000);

    }

  }

  



  return (
    <HomePageDisplay  >
      <div className="tabs tapsGamezroid">
       {/* <div className="task">{parent}</div> */}
        <MainTab
          mainTabsInfo={mainTabsInfo}
          activeMainTab={active.main}
          setActiveMainTab={handleMainTabChange}
        />
        {urlcompetition ? (
          <Competition />

        ) : ""

        }

        {urlgamezroid && showDouble ?
          <div className="globalcoins">
            <img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
          </div>
          : ""}

        {showslider ? (
          <SliderCompation
            mainTabsInfo={mainTabsInfo}
            games={[homeGamesSlider]}
            activeMainTab={active.main}
            setActiveMainTab={handleMainTabChange} />
        ) : ""}

        <SubTab
          subTabsInfo={subTabs}
          activeSubTab={active.sub}
          setActiveSubTab={handleSubTabChange}
        />

        {urlgamezroid ? (
          <Competition />

        ) : ""

        }

      </div>

      <Modal
        visible={instVisible}
        width="338"
        height="450px"
        effect="fadeInUp"
        padding="0"
        onClickAway={() => instCloseModal()}>
        <div className="instModel">
          <a href="javascript:void(0);" onClick={() => instCloseModal()}> X </a>
        </div>
      </Modal>

      {loading.status && <Loader type={loading.type} />}

      {!loading.status && (
        <>
          {showexclusive && urlksa ?
            (<MainExclusiveGame
              sliders={[activeExclusiveSliders]}
              setActiveCategory={handleCategoryChangeex} />) : ""}


          {active.category !== "" ? 
          (
            <CategoryGames
              name={activeCategoryName}
              category={active.category}
              games={categoryGames}
            />
          )
          : active.sub !== "" ? (
            <FilteredGames games={subTabGames} />

          ) :
            <MainCategorySliders
              sliders={[...activeSliders]}
              setActiveCategory={handleCategoryChange} />
          }
        </>
      )

      }


    </HomePageDisplay>
  );
}
export default withTranslation()(HomePage);
function getSubTabs(subTabsObject) {
  const subTabsArray = Object.keys(subTabsObject);

  const subTabs = subTabsArray.map(i => {
    return { name: h.slugifyReverse(i), slug: i };
  });
  return subTabs;
}
const countryName = localStorage.getItem('country_name');
const operatorName = localStorage.getItem('operator_name');

const getCategoryGames = async (slug, main) => {
  const api = `${envo.apiUrl}/category/getgames?category=${slug}&type=${main}&country=${countryName}&operator=${operatorName}`;
  const res = await axios.get(api);
  const data = res.data;
  if (data.status === "success") {
    const resGame = data.games.map(i => {
      return {
        img: i.game_thumb,
        name: i.game_name,
        nameAr: i.game_name_ar,
        id: i._id
      };
    });
    // //console.log('Game' , resGame);
    return resGame;
  }
  return [];
};


