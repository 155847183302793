import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import "./SignupPage.styled";
import "./MauritaniaSignupPage.scss";
import MauritaniaSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { getRedirectUrl, subscribe } from "./services";
import Modal from 'react-awesome-modal';
import Operators from "../../../operators/operators.json"
import i18n from "../../../i18n/i18n"
import * as localLang from "../../../i18n/i18n";
import { useHistory } from "react-router";

import { useAlert } from 'react-alert'

// import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css'; 


const randomBg = bgSliders.getRandomBg();

function MauritaniaSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [loading, setloading] = useState(false);
  const [showwaiting, setshowwaiting] = useState(false);

  const [signupPlan, setSignupPlan] = useState("2271");
  const [productCatalog, setProductCatalog] = useState("");
  const [planPrice, setPlanPrice] = useState("5");
  const [planCode, setPlanCode] = useState("2271");
  const [networkName, setNetworkName] = useState("");
  const [planName, setplanName] = useState("Daily");
  const [messageCode, setmessageCode] = useState("");
  const [operatorCode, setOperatorCode] = useState("");
  const [counter, setCounter] = useState(60);
  const [minutes, setMinutes] = useState(1);
  const [SessionToken, setSessionToken] = useState("");
  const [showconditionplan, setShowconditionplan] = useState(true);
  const [showconditionnetwork, setShowconditionnetwork] = useState(false);
  const [showtimer, setShowTimer] = useState(false);
  const [resourceUrl, setResourceUrl] = useState("");
  const [visablemodalverify, setvisablemodalverify] = useState(false);
  const [visablemodalSubscribe, setvisablemodalSubscribe] = useState(false);
  const [oper, setoper] = useState("");
  const [enriched, setenriched] = useState(false);
  const [noenriched, setnoenriched] = useState(true);
  const [visablModaldataphone, setvisablModaldataphone] = useState(false);

  const [Msisdn, setMsisdn] = useState("");
  const [referenceCode, setReferenceCode] = useState("");
  const [whiteStyle, setwhiteStyle] = useState(false);
  const [showPlay, setshowPlay] = useState(true);
  const [startpage, setstartpage] = useState(true);
  const [UrlImg1, setUrlImg1] = useState("");
  const [UrlImg2, setUrlImg2] = useState("");
  const [UrlImg3, setUrlImg3] = useState("");
  const [gcookies, setgcookies] = useState("");



  const history = useHistory();
  const alert = useAlert()


  function readCookies() {
    var ca = document.cookie.split(';');
    var cname = '_ga'
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        console.log("cookies", c.substring(name.length, c.length));
        setgcookies(c.substring(name.length, c.length))
        //return c.substring(name.length, c.length);
      }
    }


  }






  useEffect(() => {
   //alert.show(<div style={{ color: 'white' , backgroundColor:'red' }}>Some Message</div>)

    ////alert.show('Oh look, an alert!')

    // alert("please enter correct e-mail");

    // alert("is Header Enriched  task");

    readCookies();
    localLang.setlangSubscribe();
    changeStyle();
  
    var language = localStorage.getItem("i18nextLng");
    const query = queryString.parse(window.location.search);
    const SessionIdVar = query.SessionId;
    axios.get('http://api.playit.mobi/api/v2/getTpayMitJsURL?language=' + language + '&sessionId=' + SessionIdVar)
      .then(res => {
        // setResourceUrl(res.data.url);
        loadLibrary(res.data.url);
      })

    //if (query.SessionToken=="") {
    setstartpage(false);
    setOperatorCode(query.OperatorCode);
    setSessionToken(query.SessionToken);
    // setSessionToken(query.ed);

    if (query.SubId != null && query.SubId !== '') {
      window.localStorage.setItem('subscriptionContractId', query.SubId);
      setvisablemodalSubscribe(true)
    }

    localLang.checklocalLanguage();

    ////}

    // set tracker
    const url = window.location.search;
    if (url !== "") {
      setAgencyData(url);
    }

    console.log("noenriched", noenriched);
    console.log("showverif", showverif);
    console.log("enriched", enriched);
    console.log("startpage", startpage);
    console.log("showPlay", showPlay)
    console.log("loading", loading);
  }, []);


  const changeStyle = () => {
    var url = window.location.href;
    const btn = document.getElementById('signup');
    const header = document.querySelector('.header');
    const content = document.querySelector('.gamezoridcontent');
    const play = document.querySelector('.play');
    const logo = document.querySelector('.logo');
    const gamesInfo = document.getElementsByClassName('games-info');
    const iconsImg = document.getElementsByClassName('iconsimg');
    const priceText = document.querySelector('.freesubscribe');
    const conditionText = document.querySelector('.ncellTos');
    const decilimer = document.getElementsByClassName('decilimer');
    const operatorSignup = document.querySelector('.operatorSignup');
    var inputPhone;
    if (url.includes("signup21") || url.includes("signup51") || url.includes("signup61")) {
      const btn_style = {
        color: 'white',
        backgroundColor: '#BB2F14',
        border: '1px solid red'
      };

      header.style = "border-bottom: 2px solid #BB2F14;"
      content.style = 'color:black';
      priceText.style = 'color :black';
      // verifyInput.style = 'color :black';
      conditionText.style = 'color :black';
      operatorSignup.style = 'background :#fff';
      logo.style = "background: url('/assets/images/logosignup2.svg') center center / cover no-repeat";

      if (startpage === false) {
        inputPhone.style = 'background :#383535';

      }
      // if(showverif === true && startpage === false)
      // {
      //   const verifyInput = document.getElementById('verifyInput');
      //   console.log("task")
      //   verifyInput.style = 'color :black';
      // }
      for (var i = 0; i < gamesInfo.length; i++) {
        gamesInfo[i].style.color = "#601D10";
      }
      for (var i = 0; i < decilimer.length; i++) {
        decilimer[i].style.color = "black";
      }
      iconsImg[0].setAttribute('src', '/assets/images/subscribegamezriod/redIcons/like.png')
      iconsImg[1].setAttribute('src', '/assets/images/subscribegamezriod/redIcons/Repeat.png')
      iconsImg[2].setAttribute('src', '/assets/images/subscribegamezriod/redIcons/group.png')
      Object.assign(btn.style, btn_style);

    }

    if (url.includes("signup31")) {
      const btn_style = {
        backgroundColor: '#E09C24',
        border: '1px solid #BB2F14'
      };
      header.style = "border-bottom: 2px solid #BB2F14;"
      operatorSignup.style = 'background :black';
      play.style = "background: url('/assets/images/Path29.svg') center center / cover no-repeat; height:242px";
      Object.assign(btn.style, btn_style);
    }

    if (url.includes("signup41")) {
      setshowPlay(false)
      const btn_style = {
        backgroundColor: '#C9FE3B',
        border: '1px solid #F2D12C'
      };
      header.style = "border-bottom: 2px solid #89BF2F;"
      operatorSignup.style = 'background :black';
      play.style = "background: url('/assets/images/Path29.svg') center center / cover no-repeat";
      Object.assign(btn.style, btn_style);
    }

    if (window.location.href.includes("signup21") || window.location.href.includes("signup51") || window.location.href.includes("signup61")) {
      setwhiteStyle(true);
      setUrlImg1("/assets/images/subscribegamezriod/redIcons/like.png")
      setUrlImg2("/assets/images/subscribegamezriod/redIcons/Repeat.png")
      setUrlImg3("/assets/images/subscribegamezriod/redIcons/group.png")
    }
    else {
      setUrlImg1("/assets/images/subscribegamezriod/like.png")
      setUrlImg2("/assets/images/subscribegamezriod/Repeat.png")
      setUrlImg3("/assets/images/subscribegamezriod/group.png")

    }

  }

  const directLink = () => {
    // direct to link
    const query = queryString.parse(window.location.search);
    var url = window.location.href;
    var number = "";
    //console.log(query);
    //if (query.Details === null) {
    axios.get('http://www.api.playit.mobi/api/v2/getTpayMitURL?number=')
      .then(res => {
        window.location.href = res.data.url;
      })
    //}

  }

  //load library 


  const loadLibrary = (source) => {
    // console.log('load librrary')
    const popupscript = document.createElement('script');
    popupscript.id = "popupscript";
    popupscript.src = source;
    popupscript.async = true;

    document.body.appendChild(popupscript);
    
    setloading(true);
    // console.log("noenriched", noenriched , enriched , startpage);
    // confirmAlert({
    //   title: 'Confirm to submit',
    //   message: 'Are you sure to do this.',
    //   buttons: [
    //     {
    //       label: 'Yes',
    //       onClick: () => alert('Click Yes'),
    //     },
    //     {
    //       label: 'No',
    //       onClick: () => alert('Click No'),
    //     },
    //   ],
    // });
    console.log('before load script by id');
    sendlog('before load script by id');
    document.getElementById("popupscript").addEventListener('load', () => {
      console.log('after load script by id')
      document.body.appendChild(popupscript);
      popupscript.src = source;
      var headerenriched = window.TPay.HeaderEnrichment.enriched();
      console.log(headerenriched , 'headerenriched');

      //alert.show("is Header Enriched "+headerenriched);

      if (headerenriched) {
        setloading(false);
        //setshowwaiting(true)
        setenriched(true);
        const operator = window.TPay.HeaderEnrichment.operatorCode();
        //alert.show("window.TPay.HeaderEnrichment.operatorCode "+operator);
        networkGamezroid.map((c) => {
          if (c.code === operator) {
            setNetworkName(c.name);
            setmessageCode(c.code_message);
          }
        })
        //TpayConfirm();
        // setTimeout(() => {
           //showpopsubscribe();
        // }, 3000);
      }
      else {
        window.TPay.HeaderEnrichment.init({ operatorCode: '60902'});
        setshowwaiting(true)

        console.log("noload");
        setloading(false);
        setenriched(false);
        setnoenriched(true)
        setstartpage(false);
        //setcheckenriched(false)
        //setnoenriched(true)
        //document.getElementById('signupcheck').style = "display : none";
      }
      // DTM is loaded
     })

   
    // popupscript.onload = () => {
    
    // }
    setloading(false);
    // console.log('1213212');
    document.body.appendChild(popupscript);
    //document.head.appendChild(popupscript)

  }

  const closeModalverify = () => {
    setvisablemodalverify(false)
  };

  const closeModalSubscribe = () => {
    setvisablemodalSubscribe(false)
  };


  const sendData = async e => {
    e.preventDefault();
    const subscriptionContractId = localStorage.getItem('subscriptionContractId');
    const language = localStorage.getItem("i18nextLng");
    var header;
    (enriched === true) ? header = '1' : header = '0';
    const options = {
      subscriptionContractId,
      language,
      header
    };
    axios.post("http://www.api.playit.mobi/api/v1/resendacount", options)
      .then(res => {
        if (res.data.status === true) {
          setvisablemodalSubscribe(false);
          window.location.href = "/"
        }
        else {
          setvisablemodalSubscribe(false);
          setErrorMesgSubscribe(res.data.message);
          setErrorMesg(true);
        }
        //console.log(res)

      })
      .catch(error => {
        //console.log(error)
        setvisablemodalSubscribe(false);
      }

      )

  }

  useEffect(() => {

    var timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    if (minutes > 0 && counter === 0 && showtimer === true) {
      setMinutes(minutes - 1)
      setCounter(60)
    }
    if (counter === 0 && minutes === 0 && showtimer === true) {
      // document.getElementById("signup").removeAttribute("disabled");
      setShowTimer(false);
      setErrorMesg(false)

    }

    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  const APImsisdn = {
    bhmsisdn: ''
  }

  const plans = [
    {
      code: "2271",
      name: "Daily Plan",
      price: 5
    },
    {
      code: "2272",
      name: "Weekly Plan",
      price: 20
    },
    {
      code: "2273",
      name: "Monthly Plan",
      price: 30
    }
  ];

  const plansGame = [
    {
      code: "2271",
      name: "Daily",
      price: 5
    },
    {
      code: "2272",
      name: "Weekly",
      price: 20
    },
    {
      code: "2273",
      name: "Monthly",
      price: 30
    }

  ];

  const networkGamezroid = [
    {
      code: "60902",
      name: "Vodafone",
      code_message: "2303",

    }

  ];

  const [alert2, setAlert] = useState(false);

  const plansOptions = plans.map((c) => {
    return (
      <option value={c.code}>
        {t(c.name)}
      </option>
    );
  });

  const plansOptionsGamezroid = plansGame.map((c) => {
    return (
      <option value={c.code} selected>
        {t(c.name)}
      </option>
    );
  });

  const OptionsGamezroid = networkGamezroid.map((c) => {
    return (
      <option value={c.code} selected={operatorCode === c.code}>
        {t(c.name)}
      </option>
    );
  });

  const sendlog = async(logdata) => {
    const url = "http://www.api.playit.mobi/api/v2/tpayMit/savelog";
    const options = {
     logdata
    };
    console.log('options', options);

    const res = await axios.post(url, options);
    if (res.data) {
      console.log(res.data);
     
    }
  }

  const logmsisdnlang = async(msisdn,lang) => {
    const url = "http://www.api.playit.mobi/api/v2/tpayMit/logmsisdnlang";
    const options = {
      msisdn:msisdn,
      lang:lang
    };
    console.log('options', options);

    const res = await axios.post(url, options);
    if (res.data) {
      console.log(res.data);
     
    }
  }


  const checkheaderenriched = (e) => {
    e.preventDefault();
    console.log('Checking')
    directLink();
  }

  const Subscribe = async (e) => {
    e.preventDefault();
    
    if (activeData) {
      var phon_n = activeData;
      // if (!phon_n.slice(0, 4).includes('244')) {
      //   phon_n = '244' + phon_n;
      // }

      try {
        var initialPaymentproductId = 'playitMT-daily';
        if (signupPlan === '2272') {
          initialPaymentproductId = 'playitMT-weekly';
        }
        if (signupPlan === '2273') {
          initialPaymentproductId = '	playitMT-monthly';
        }
        var msisdn = phon_n;
        //console.log(msisdn)

        var countryCode = '222';
        var operatorCode = productCatalog;
        var productCatalogName = '';
        var language = localStorage.getItem("i18nextLng");
        productCatalogName = "PlayIT"
        var subscriptionPlanId = Number(signupPlan);


        const url = "http://www.api.playit.mobi/api/v2/tpayMit/newContractRoid";
        const options = {
          msisdn,
          countryCode,
          operatorCode,
          subscriptionPlanId,
          productCatalogName,
          initialPaymentproductId,
          language,
          SessionToken,
          trackerCompany: localStorage.getItem("trackerCompany"),
          tracker: localStorage.getItem('tracker'),
          _ga: gcookies

        };
        console.log('options', options);

        const res = await axios.post(url, options);
        if (res.data) {
          const data = res.data;
          if (res.data.status === true) {
            window.localStorage.setItem('subscriptionContractId', res.data.subscriptionContractId);
            setShowverif(true)
            setErrorMesg(false)

          } else {
            console.log("here");
            console.log(res.data);

            if (res.data.status === false && res.data.subscriptionContractId) {
              console.log("here1");
              window.localStorage.setItem('subscriptionContractId', res.data.subscriptionContractId);
              setvisablemodalSubscribe(true);

            }
            else {
              console.log("here1");
              setErrorMesgSubscribe(res.data.message)
              setErrorMesg(true);
              setCounter(60)
              setShowTimer(true)
              document.getElementById("signup").setAttribute("disabled", 'disabled')
              setShowverif(false);
            }

          }
        }
      } catch (err) {
        //console.log(err);
        setErrorMesg(true)
        setShowverif(false)
      }

    }

  };

  const TpaySubscribefirst = async (Msi, headerEnrichmentReferenceCode, sessionToken) => {
    try {
      var initialPaymentproductId = 'playitMT-daily';
      if (signupPlan === '2272') {
        initialPaymentproductId = 'playitMT-weekly';
      }
      if (signupPlan === '2273') {
        initialPaymentproductId = '	playitMT-monthly';
      }
      var msisdn = Msi;
      var countryCode = '222';
      var operator = window.TPay.HeaderEnrichment.operatorCode();
      var language = localStorage.getItem("i18nextLng");
      var productCatalogName = 'PlayIT';
      var subscriptionPlanId = Number(signupPlan);
      const url = "http://www.api.playit.mobi/api/v2/tpayMit/newContractRoid";
      const options = {
        msisdn,
        countryCode,
        operatorCode: operator,
        subscriptionPlanId,
        productCatalogName,
        initialPaymentproductId,
        language,
        SessionToken: sessionToken,
        headerEnrichmentReferenceCode,
        trackerCompany: localStorage.getItem("trackerCompany"),
        tracker: localStorage.getItem('tracker'),
        _ga: gcookies

      };
      const res = await axios.post(url, options);
      if (res.data) {
        if (res.data.status === true) {
          window.localStorage.setItem('subscriptionContractId', res.data.subscriptionContractId);
          const GMSISDN = res.data.username;
          setErrorMesg(false);
          trackerEnrichment(GMSISDN, operator);
          setvisablemodalverify(true);
          setTimeout(() => {
            setvisablemodalverify(false);
            //history.push('/thankyou')
            // window.location.href = "/"
          }, 3000);

        } else {
          if (res.data.status === false && res.data.subscriptionContractId) {

            console.log("here11");
            window.localStorage.setItem('subscriptionContractId', res.data.subscriptionContractId);
            setvisablemodalSubscribe(true);
          }
          setErrorMesgSubscribe(res.data.message)
          setErrorMesg(true);
          setCounter(60)
          setShowTimer(true)
          document.getElementById("signupenriched").setAttribute("disabled", 'disabled')
          setShowverif(false);

        }
      }
    } catch (err) {
      setErrorMesg(true)
      setShowverif(false)
    }

  }



  const TpaySubscribe = async (msisdn,sessionToken,operatorCode,headerEnrichmentReferenceCode) => {
    try {
      var initialPaymentproductId = 'playitMT-daily';
      if (signupPlan === '2272') {
        initialPaymentproductId = 'playitMT-weekly';
      }
      if (signupPlan === '2273') {
        initialPaymentproductId = '	playitMT-monthly';
      }

      var countryCode = '222';
      var operator = window.TPay.HeaderEnrichment.operatorCode();
      var language = localStorage.getItem("i18nextLng");
      var productCatalogName = 'PlayIT';
      var subscriptionPlanId = Number(signupPlan);
      const url = "http://www.api.playit.mobi/api/v2/tpayMit/newContractRoid";
      const options = {
        msisdn,
        countryCode,
        operatorCode: operator,
        subscriptionPlanId,
        productCatalogName,
        initialPaymentproductId,
        language,
        SessionToken: sessionToken,
        headerEnrichmentReferenceCode,
        trackerCompany: localStorage.getItem("trackerCompany"),
        tracker: localStorage.getItem('tracker'),
        _ga: gcookies

      };

      const res = await axios.post(url, options);
      if (res.data) {
        setshowwaiting(false)
        if (res.data.status === true) {
          window.localStorage.setItem('subscriptionContractId', res.data.subscriptionContractId);
          const GMSISDN = res.data.username;
          setErrorMesg(false);
          trackerEnrichment(GMSISDN, operatorCode);
          setvisablemodalverify(true);
          setTimeout(() => {
            setvisablemodalverify(false);
            //history.push('/thankyou')
            // window.location.href = "/"
          }, 3000);

        } else {
          if (res.data.status === false && res.data.subscriptionContractId) {

            console.log("here11");
            window.localStorage.setItem('subscriptionContractId', res.data.subscriptionContractId);
            setvisablemodalSubscribe(true);
          }
          setErrorMesgSubscribe(res.data.message)
          setErrorMesg(true);
          setCounter(60)
          setShowTimer(true)
          document.getElementById("signupenriched").setAttribute("disabled", 'disabled')
          setShowverif(false);

        }
      }
    } catch (err) {
      setErrorMesg(true)
      setShowverif(false)
    }

  }



  const TpayConfirm = async() =>
  {
    sendlog("start TpayConfirm");
    var initialPaymentproductId = 'playitMT-daily';
    if (signupPlan === '2272') {
      initialPaymentproductId = 'playitMT-weekly';
    }
    if (signupPlan === '2273') {
      initialPaymentproductId = '	playitMT-monthly';
    }
    let msisdn = window.TPay.HeaderEnrichment.msisdn();
   //alert.show("HeaderEnrichment.msisdn"+msisdn);
    setMsisdn(msisdn);
    let operatorCode = window.TPay.HeaderEnrichment.operatorCode();
    let sessionToken = window.TPay.HeaderEnrichment.sessionToken();
    //alert.show("HeaderEnrichment.operatorCode"+operatorCode);
    //alert.show("HeaderEnrichment.sessionToken"+sessionToken);
    console.log(sessionToken);
    let options = {
      subscriptionPlanId: Number(signupPlan),
      productCatalog: 'PlayIT',
      productSku: initialPaymentproductId, 
       msisdn: msisdn,  
       operatorCode: operatorCode,
      //  customerAccountNumber: customerAccountNumber
    }
    console.log(options);
    sendlog("options");
    sendlog(options);
   //alert.show("window.TPay.HeaderEnrichment.confirm Start");
    window.TPay.HeaderEnrichment.confirm(options , function (status, refCode, contract)
    {
     //alert.show("Status: "+status+ " refCode" + refCode+ " contract" + contract);
      if (status  && refCode== null && contract == null)
      {
        // api flow  
      }
      else if (status === true  && refCode && contract == null)
      {
        TpaySubscribe(msisdn,sessionToken,operatorCode,refCode,enriched)
        // HE flow
      }

      else if (status && refCode && contract)
      {
        // redirection flow
      }

      else 
      {
        // handle error message
      }


      // if (contract) 
      // { return contract; }

    })

   

    
  }

  const showpopsubscribe = () => {
    console.log("popup show")
    var initialPaymentproductId = 'playitMT-daily';
    if (signupPlan === '2272') {
      initialPaymentproductId = 'playitMT-weekly';
    }
    if (signupPlan === '2273') {
      initialPaymentproductId = '	playitMT-monthly';
    }

    var operator = window.TPay.HeaderEnrichment.operatorCode();
    var productCatalogName = 'PlayIT';
    setshowwaiting(false)
    sendlog("operator: "+operator);
    //if (window.TPay.HeaderEnrichment.operatorCode() === operator) {
      sendlog("hasSub: ");
      sendlog(window.TPay.HeaderEnrichment.hasSubscription());
      window.TPay.HeaderEnrichment.hasSubscription(initialPaymentproductId, async (hasSub, subId) => {
        sendlog("hasSub: ");
        sendlog(hasSub);
        sendlog("subId: ");
        sendlog(subId);
        if (!hasSub) {
          console.log("HeaderEnrichment.confirm"+"  befoer confirm");
          let msisdn = window.TPay.HeaderEnrichment.msisdn();
          setMsisdn(msisdn);
          let options = {
              subscriptionPlanId: Number(signupPlan),
              productCatalog: 'PlayIT',
              productSku: initialPaymentproductId, 
              customerAccountNumber: msisdn
          }
          console.log(options);
          
          var language = localStorage.getItem("i18nextLng");
          logmsisdnlang(msisdn,language);

          window.TPay.HeaderEnrichment.confirm(options, async (result, refNo,contract) => {
            //alert.show("status= ");
            // sendlog("Status = ");
            // sendlog(result);
            // sendlog("contract = ");
            // sendlog(contract);
            // sendlog("refNo = "+refNo);
            
            /*if (!result) {
              console.log("status=" + result);
              return;
            }*/

            if (result  && refNo== null && contract == null)
            {
              // api flow  
              setloading(false);
              setenriched(false);
              setnoenriched(true)
              setstartpage(false);
            }
            else if (result === true  && refNo && contract == null)
            {
                const msisdnFormJs = window.TPay.HeaderEnrichment.msisdn();
                tracker(msisdnFormJs);
                setMsisdn(msisdnFormJs);
                const SessionTokenFromjs = window.TPay.HeaderEnrichment.sessionToken()
                setSessionToken(SessionTokenFromjs);              
                TpaySubscribe(msisdnFormJs, SessionTokenFromjs,operatorCode,refNo,enriched);
            }
            else if (result && refNo && contract)
            {
              // redirection flow
              //setenriched(false);
              const msisdnFormJs2 = window.TPay.HeaderEnrichment.msisdn();
              tracker(msisdnFormJs2);
              window.localStorage.setItem('subscriptionContractId', contract);
              setTimeout(() => {
                setvisablemodalverify(false);
                history.push('/thankyou');
    
                // window.location.href = "/"
              }, 3000);
    
              // if (res.data.user_id) {
              //   setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn, res.data.subscriptionContractId);
              // }
    
            }
            else 
            {
              //setErrorMesgSubscribe("")
              //setErrorMesg(true);
              setloading(false);
              setenriched(false);
              setnoenriched(true)
              setstartpage(false);
            }

          /*if (result === true  && refNo){
              const msisdnFormJs = window.TPay.HeaderEnrichment.msisdn();
              setMsisdn(msisdnFormJs);
              const SessionTokenFromjs = window.TPay.HeaderEnrichment.sessionToken()
              setSessionToken(SessionTokenFromjs);              
             TpaySubscribe(msisdnFormJs, SessionTokenFromjs,operatorCode,refNo,enriched)

            } else {
              console.log("error is:" + refNo);
            }
            return contract;*/

          });
        }
        else {
          console.log("HeaderEnrichment.hasSub start ");
          var subscriptionContractId = subId;
          console.log("HeaderEnrichment.hasSub "+subscriptionContractId);
          sendlog("HeaderEnrichment.hasSub");
          sendlog(subscriptionContractId);
          window.localStorage.setItem('subscriptionContractId', subscriptionContractId);
          setvisablemodalSubscribe(true);

        }
      });
    //}




  }

  const verify = async e => {
    e.preventDefault();

    try {
      var subscriptionContractId = localStorage.subscriptionContractId;
      var pinCode = verifyCode;
      var language = localStorage.getItem("i18nextLng");
      const url = "http://www.api.playit.mobi/api/v2/tpayMit/verify";
      const options = {
        subscriptionContractId,
        pinCode,
        language
      };
      const res = await axios.post(url, options);
      if (res.data) {

        const data = res.data;
        if (res.data.status === true) {
          const GMSISDN = res.data.username;
          setVerfiErrorMesg(false)
          tracker(GMSISDN);
          setvisablemodalverify(true);
          if (window.location.href.includes('signup6')) {
            localStorage.setItem("routing", "signup6")
          }
          setTimeout(() => {
            setvisablemodalverify(false);
            //history.push('/thankyou')

            // window.location.href = "/"
          }, 3000);

          if (res.data.user_id) {
            setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn, res.data.subscriptionContractId);
          }


        } else {
          setVerfiErrorMesg(true)
        }
      }
    } catch (err) {
      //console.log(err);
      setErrorMesg(true)
      setVerfiErrorMesg(true)
      setShowverif(false)
    }

  };

  const resend = async e => {
    e.preventDefault();
    //const subscriptionContractId = 223579649
    const subscriptionContractId = localStorage.getItem('subscriptionContractId');

    const options = {
      subscriptionContractId,

    }
    axios.post("http://www.api.playit.mobi/api/v2/tpayMit/resend", options)
      .then(res => {
        //console.log(res)

      })
      .catch(error => console.log(error))

  }

  // save tracker
  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem('tracker')) {
      var op_id = "sHmLrSiBwy";

      console.log(GMSISDN)
 
      datag = {
        key: 'parameter',
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem('tracker'),
        identifierKey: 'id',
        identifierValue: op_id,
        phoneNum: GMSISDN
      };
      console.log(datag)

    }
    await saveTracker(datag);

  }

  // save Enrichment tracker
  const trackerEnrichment = async (GMSISDN, operatorCode) => {
    var datag = {};
    if (localStorage.getItem('tracker')) {
      var op_id = ""
      if (operatorCode === "60201") {
        op_id = Operators.Tpayegypt012;
      }
      if (operatorCode === "60202") {
        op_id = Operators.Tpayegypt010;
      }
      if (operatorCode === "60203") {
        op_id = Operators.Tpayegypt011;
      }
      if (operatorCode === "60204") {
        op_id = Operators.Tpayegypt015;
      }

      datag = {
        key: 'parameter',
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem('tracker'),
        identifierKey: 'id',
        identifierValue: op_id,
        phoneNum: GMSISDN
      };

    }
    await saveTracker(datag);

  }

  const onInputChange = (name, value) => {
    console.log("val", value)
    if (name === 'productCatalog') {
      setProductCatalog(value);
      const network = networkGamezroid.map((c) => {
        if (c.code === value) {
          setNetworkName(c.name);
          setmessageCode(c.code_message);
          setShowconditionnetwork(true)
        }
      })
    }
    if (name === 'signupPlan') {
      console.log("value", value);
      setSignupPlan(value);
      console.log("signupPlan", signupPlan);
      const price = plansGame.map((c) => {
        if (c.code === value) {
          console.log("c.price", c.price);
          setPlanPrice(c.price);
          console.log(planPrice);
          setplanName(c.name)
          console.log("c.name", c.name);
          console.log(planName);
          setPlanCode(c.code)
          console.log(planCode);
          setShowconditionplan(true)
        }
      })

      if (enriched === true) {
        const operator = window.TPay.HeaderEnrichment.operatorCode();
        networkGamezroid.map((c) => {
          if (c.code === operator) {
            setNetworkName(c.name);
            setmessageCode(c.code_message);
          }
        })


      }

    }
    if (name === 'activeData') {
      document.getElementById("phone").addEventListener("keypress", function (evt) {
        if (evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
      });

      console.log("pro")
      setProductCatalog('60902');
      setNetworkName('PlayIT');
      setmessageCode('1722');
      setShowconditionnetwork(true) 

      //   var startphoneMauritania = value.startsWith('2' );
      // if (!startphoneMauritania) {
      //   value = '2' + value;
      // }

      setActiveData(value);
    }

    if (name === 'verifycode') {
      document.getElementById("verifyInput").addEventListener("keypress", function (evt) {
        if (evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
      });
      setVerifyCode(value)
    }
    // this.setState({
    //   [name]: value,
    //   errors: false
    // });
  }

  return (
    <MauritaniaSignupPageDisplay className="" >
      {alert2 && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.VERIFYERROR</Trans>
        </FloatingAlert>
      ) : null}





      {/*showwaiting && !startpage && !enriched ?
        <div>
          <div className="spinerC">
            <div className="spinner">
              <p><Trans>waiting</Trans></p>

            </div>
          </div>

        </div>
      : ""} */}









      {loading && !startpage && !showwaiting ?
        <div>
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div> : ""}

      {showwaiting && enriched && !startpage ?
        <div className="container-loadaing waiting-loading">
          <div className="loadingLayout">
            <div className="keep-waiting">
              <div>


                <div className="sk-chase">

                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                </div>
              </div>
              <div>
                <p><Trans>waiting</Trans></p>

                {/* <h1>برجاء الانتظار</h1> */}
              </div>
            </div>


          </div>

        </div>
          
        : ""
      }


      <div className="operatorSignup operatorSignupGamez">
        <div className="formsLayout formsLayoutgamezorid">
          <div className="header">
            <Link to="/">
              <div className="logogamezroid logo" ></div>
            </Link>
          </div>

          {startpage ?
          
              <div className="links-Section links-SectionGamezorid v1">

                <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                  <Trans>Your content is ready!</Trans>

                </h3>

                {showPlay ?
                  <div className="play"></div>
                  : ""}
                {!showPlay ?
                  <div className="steps">
                    <div className="stepLists">
                      <ul>
                        <li><img src="/assets/images/numbers/number1.png" alt="img" /></li>
                        <li><img src="/assets/images/numbers/number2.png" alt="img" /></li>
                        <li><img src="/assets/images/numbers/number3.png" alt="img" /></li>
                      </ul>
                      <ul>
                        <li><h4><Trans>CONTENT.phone</Trans></h4></li>
                        <li><h4><Trans>CONTENT.verify</Trans></h4></li>
                        <li><h4><Trans>CONTENT.enjoy</Trans></h4></li>
                      </ul>
                    </div>
                  </div> : ""}

                <p className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.FREE</Trans> <span style={{ color: 'red' }}>{planPrice} <Trans>SUBSCRIBEGAMEZORIDE.PRICE</Trans> <Trans>{planName}</Trans></span> <Trans>SUBSCRIBEGAMEZORIDE.AUTO</Trans></p>
                <p className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.INTERNET</Trans></p>

                <button
                  id="signup1"
                  className="btn gamezroid btn_gamezroid" onClick={e => {
                    checkheaderenriched(e);
                  }}
                >
                  <Trans>Subscribe_gamezroid</Trans>
                </button>

                <div className="row text-center" >
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                      <p><Trans>evaluation</Trans></p>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                      <p><Trans>game</Trans></p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                      <p><Trans>user</Trans></p>
                    </div>
                  </div>
                </div>

                <div className="footer" style={{ marginBottom: '25px' }}>
                  <div className="ncellTos">

                    <div className="termsCondition" style={{ textAlign: 'center' }}>
                      <p className="decilimer">
                        <Trans>part1</Trans> <span className="fixedprice" style={{ color: 'red' }}> {planPrice} <Trans>price</Trans> / <Trans>{planName}</Trans></span>. <Trans>part2</Trans> <Trans>part3</Trans>
                      </p>

                    </div>

                    <br></br>
                    <div className="termsCondition">
                      <ul style={{ lineHeight: '18px' }}>
                        <li><Trans>cancelsubscribegamezroid</Trans></li>
                        <li><Trans>browsing cost</Trans></li>
                        <li><Trans>supportgamezroid</Trans></li>
                        <li><Trans>termcondtion_gamezroid</Trans></li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            
            : ""}


          {enriched && !showverif ?

           <div className="links-Section links-SectionGamezorid v1">

           <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
             <Trans>Your content is ready!</Trans>

           </h3>

           {showPlay ?
             <div className="play"></div>
             : ""}
           {!showPlay ?
             <div className="steps">
               <div className="stepLists">
                 <ul>
                   <li><img src="/assets/images/numbers/number1.png" alt="img" /></li>
                   <li><img src="/assets/images/numbers/number2.png" alt="img" /></li>
                   <li><img src="/assets/images/numbers/number3.png" alt="img" /></li>
                 </ul>
                 <ul>
                   <li><h4><Trans>CONTENT.phone</Trans></h4></li>
                   <li><h4><Trans>CONTENT.verify</Trans></h4></li>
                   <li><h4><Trans>CONTENT.enjoy</Trans></h4></li>
                 </ul>
               </div>
             </div> : ""}

           <p className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.FREE</Trans> <span style={{ color: 'red' }}>{planPrice} <Trans>SUBSCRIBEGAMEZORIDE.PRICE</Trans> <Trans>{planName}</Trans></span> <Trans>SUBSCRIBEGAMEZORIDE.AUTO</Trans></p>
           <p className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.INTERNET</Trans></p>

           <button
             id="signup1"
             className="btn gamezroid btn_gamezroid" onClick={e => {
               showpopsubscribe(e);
             }}
           >
             <Trans>Subscribe_gamezroid</Trans>
           </button>

           <div className="row text-center" >
             <div className="col-xl-4 col-lg-4 col-4 px-5">
               <div className="games-info">
                 <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                 <p><Trans>evaluation</Trans></p>
               </div>
             </div>

             <div className="col-xl-4 col-lg-4 col-4 px-5">
               <div className="games-info">
                 <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                 <p><Trans>game</Trans></p>
               </div>
             </div>
             <div className="col-xl-4 col-lg-4 col-4 px-5">
               <div className="games-info">
                 <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                 <p><Trans>user</Trans></p>
               </div>
             </div>
           </div>

           <div className="footer" style={{ marginBottom: '25px' }}>
             <div className="ncellTos">

               <div className="termsCondition" style={{ textAlign: 'center' }}>
                 <p className="decilimer">
                   <Trans>part1</Trans> <span className="fixedprice" style={{ color: 'red' }}> {planPrice} <Trans>price</Trans> / <Trans>{planName}</Trans></span>. <Trans>part2</Trans> <Trans>part3</Trans>
                 </p>

               </div>

               <br></br>
               <div className="termsCondition">
                 <ul style={{ lineHeight: '18px' }}>
                   <li><Trans>cancelsubscribegamezroid</Trans></li>
                   <li><Trans>browsing cost</Trans></li>
                   <li><Trans>supportgamezroid</Trans></li>
                   <li><Trans>termcondtion_gamezroid</Trans></li>

                 </ul>
               </div>
             </div>
           </div>
         </div>

            // <div className="links-Section links-SectionGamezorid v2">
            //   <h3 style={{ color: whiteStyle ? 'black' : '#fff' }} className="gamezoridcontent" id="gamezoridcon">
            //     <Trans>Your content is ready!</Trans>
            //   </h3>

            //   {showPlay ?
            //     <div className="play"></div>
            //     : ""}

            //   <p
            //     style={{ color: whiteStyle ? 'black' : '#fff' }}
            //     className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.FREE</Trans> <span style={{ color: 'red' }}>{planPrice} <Trans>SUBSCRIBEGAMEZORIDE.PRICE</Trans> <Trans>{planName}</Trans></span> <Trans>SUBSCRIBEGAMEZORIDE.AUTO</Trans></p>
            //   <p className="freesubscribe freesubscribbe" style={{ color: window.location.href.includes("signup21") || window.location.href.includes("signup61") || window.location.href.includes("signup51") ? "black" : "white" }}><Trans>SUBSCRIBEGAMEZORIDE.INTERNET</Trans></p>

            //   <div className="footer" style={{ marginBottom: '25px' }}>
            //     <div className="ncellTos">

            //     </div>


            //     <div className="termsCondition">

            //       <p
            //         style={{ color: whiteStyle ? 'black' : '#fff' }}
            //         className="decilimer">
            //         <Trans>you will subscribe in gamezroid for</Trans> <span className="fixedprice" style={{ color: 'red' }}>{planPrice} <Trans>price</Trans></span><Trans>{planName}</Trans> <Trans>to cancel your subscription for</Trans> <Trans>{networkName}</Trans> <Trans>Mauritania subscribers please send 'Unsub GRD' to</Trans> {messageCode} <Trans>for free, To cancel from the site please go to</Trans>
            //         <Link to="/unsubscribe" className="decilimer"> <Trans>here</Trans></Link>
            //       </p>

            //     </div>
            //     <br></br>

            //     <div className="termsCondition">
            //       <ul style={{ lineHeight: '18px' }}>
            //         <li><Trans>cancelsubscribegamezroid</Trans></li>
            //         <li><Trans>browsing cost</Trans></li>
            //         <li><Trans>supportgamezroid</Trans></li>
            //         <li><Trans>termcondtion_gamezroid</Trans></li>

            //       </ul>
            //     </div>

            //   </div>



            // </div>

            : ""}

          {noenriched && !showverif && !enriched && !startpage && !loading ?
            <div className="links-Section links-SectionGamezorid v3">
              <h3
                style={{ color: whiteStyle ? 'black' : '#fff', paddingBottom: '1px' }}
                className="gamezoridcontent" id="gamezoridcon">
                <Trans>Your content is ready!</Trans>
              </h3>

              {showPlay ?
                <div className="play"></div>
                : ""}
              {!showPlay ?
                <div className="steps">
                  <div className="stepLists">
                    <ul>
                      <li><img src="/assets/images/numbers/number1.png" alt="img" /></li>
                      <li><img src="/assets/images/numbers/number2.png" alt="img" /></li>
                      <li><img src="/assets/images/numbers/number3.png" alt="img" /></li>
                    </ul>
                    <ul>
                      <li><h4><Trans>CONTENT.phone</Trans></h4></li>
                      <li><h4><Trans>CONTENT.verify</Trans></h4></li>
                      <li><h4><Trans>CONTENT.enjoy</Trans></h4></li>
                    </ul>
                  </div>
                </div> : ""}

              <select
                className="select"
                defaultValue={signupPlan}
                onChange={e => onInputChange("signupPlan", e.target.value)}
                style={{ background: whiteStyle ? 'black' : '#fff', color: whiteStyle ? " #fff" : "black" }}

              >

                <Trans>{plansOptions}</Trans>
              </select>
              <div className="iconInput iconInputGame iconinputphone">
                <i className="icon phone" />
                <input
                  type="number"
                  className="input"
                  id="phone"
                  placeholder={t("enter_phone_number_gamezorid")}
                  style={{ background: whiteStyle ? 'black' : '#fff', color: whiteStyle ? " #fff" : "black" }}
                  onChange={e => onInputChange("activeData", e.target.value)}
                />
              </div>

              <p
                style={{ color: whiteStyle ? 'black' : '#fff' }}
                className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.FREE</Trans> <span style={{ color: 'red' }}>{planPrice} <Trans>SUBSCRIBEGAMEZORIDE.PRICE</Trans> <Trans>{planName}</Trans></span> <Trans>SUBSCRIBEGAMEZORIDE.AUTO</Trans></p>
              <p className="freesubscribe freesubscribbe" style={{ color: window.location.href.includes("signup21") || window.location.href.includes("signup51") || window.location.href.includes("signup61") ? "black" : "white" }}><Trans>SUBSCRIBEGAMEZORIDE.INTERNET</Trans></p>

              <button
                id="signup2"
                className="btn gamezroid btn_gamezroid" onClick={e => {
                  Subscribe(e);
                }}
              >
                <Trans>Subscribe_gamezroid</Trans>
              </button>

              <div className="row text-center"
                style={{ color: whiteStyle ? '#5f1d10' : '#fff' }} >
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info">
                    <span>98%</span><img src={UrlImg1} alt="img" className="iconsimg" />
                    <p><Trans>evaluation</Trans></p>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info">
                    <span>1000+</span><img
                      src={UrlImg2}
                      alt="img" className="iconsimg" />
                    <p><Trans>game</Trans></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info">
                    <span>500k+</span><img src={UrlImg3} alt="img" className="iconsimg" />
                    <p><Trans>user</Trans></p>
                  </div>
                </div>
              </div>


              {showtimer ?
                <div className="timer">
                  <p><Trans>Please try agin after 2 minutes</Trans></p>

                  <p><Trans>Countdown</Trans>: <p className="Countdown">{minutes} : {counter}</p></p>
                </div> : null}

              <div className="footer" style={{ marginBottom: '25px' }}>
                <div className="ncellTos">

                  {!showconditionnetwork ?
                    <>
                      <div className="termsCondition">
                        <p
                          style={{ color: whiteStyle ? 'black' : '#fff' }}
                          className="decilimer">
                          <Trans>part1</Trans> <span className="fixedprice" style={{ color: 'red' }}> {planPrice} <Trans>price</Trans> / <Trans>{planName}</Trans></span>. <Trans></Trans> <Trans>part3</Trans>
                        </p>

                      </div>

                      <br></br>
                      <div className="termsCondition">
                        <ul style={{ lineHeight: '18px' }}>
                          <li><Trans>cancelsubscribegamezroid</Trans></li>
                          <li><Trans>browsing cost</Trans></li>
                          <li><Trans>supportgamezroid</Trans></li>
                          <li><Trans>termcondtion_gamezroid</Trans></li>

                        </ul>
                      </div>

                    </>
                    : ""}

                  {!activeData && showconditionnetwork ?
                    <>
                      <div className="termsCondition">
                        <p className="decilimer"
                          style={{ color: whiteStyle ? 'black' : '#fff' }}>
                          <Trans>part1</Trans> <span className="fixedprice" style={{ color: 'red' }}> {planPrice} <Trans>price</Trans> / <Trans>{planName}</Trans></span>. <Trans>part2</Trans> <Trans>part3</Trans>
                        </p>

                      </div>
                      <br></br>
                      <div className="termsCondition">
                        <ul style={{ lineHeight: '18px' }}>
                          <li><Trans>cancelsubscribegamezroid</Trans></li>
                          <li><Trans>browsing cost</Trans></li>
                          <li><Trans>supportgamezroid</Trans></li>
                          <li><Trans>termcondtion_gamezroid</Trans></li>

                        </ul>
                      </div>
                    </>
                    : ""}


                  {showconditionnetwork && activeData ?
                    <>
                      <div>
                        <p className="decilimer"
                          style={{ color: whiteStyle ? 'black' : '#fff' }}>
                          <Trans>you will subscribe in gamezroid for</Trans> <span className="fixedprice" style={{ color: 'red' }}>{planPrice} <Trans>price</Trans></span> <Trans>{planName}</Trans> <Trans>to cancel your subscription for</Trans> <Trans>{networkName}</Trans> <Trans>Mauritania subscribers please send 'Unsub GRD' to</Trans> {messageCode} <Trans>for free, To cancel from the site please go to</Trans> 
                          <Link to="/unsubscribe" className="decilimer"> <Trans>here</Trans></Link>
                        </p>

                      </div>
                      <br></br>

                      <div className="termsCondition">
                        <ul style={{ lineHeight: '18px' }}>
                          <li><Trans>cancelsubscribegamezroid</Trans></li>
                          <li><Trans>browsing cost</Trans></li>
                          <li><Trans>supportgamezroid</Trans></li>
                          <li><Trans>termcondtion_gamezroid</Trans></li>

                        </ul>
                      </div>
                    </>
                    : null}



                </div>
              </div>

            </div>

            : ""}




          <div className="center">
            {showverif ?
              <div className="">
                <p><Trans>verifycode_signup</Trans></p>
                <input
                  style={{ color: window.location.href.includes("signup21") || window.location.href.includes("signup61") || window.location.href.includes("signup51") ? "black" : "white" }}
                  type="number"
                  className="input"
                  value={verifyCode}
                  id="verifyInput"
                  maxLength='6'
                  placeholder={t("Enter_verifycode")}
                  onChange={e => onInputChange("verifycode", e.target.value)}
                />
                <br></br>
                <p className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.FREE</Trans> <span style={{ color: 'red' }}> {planPrice} <Trans>SUBSCRIBEGAMEZORIDE.PRICE</Trans> <Trans>{planName}</Trans></span> <Trans>SUBSCRIBEGAMEZORIDE.AUTO</Trans> </p>
                <p className="freesubscribe" style={{ color: window.location.href.includes("signup21") || window.location.href.includes("signup51") || window.location.href.includes("signup61") ? "black" : "white" }}><Trans>SUBSCRIBEGAMEZORIDE.INTERNET</Trans></p>
                 
                <br></br>


                <div>
                <p className="decilimer"
                  style={{ color: whiteStyle ? 'black' : '#fff' }}>
                  <Trans>you will subscribe in gamezroid for</Trans> <span className="fixedprice" style={{ color: 'red' }}>{planPrice} <Trans>price</Trans></span> <Trans>{planName}</Trans> <Trans>to cancel your subscription for</Trans> <Trans>{networkName}</Trans> <Trans>Mauritania subscribers please send 'Unsub GRD' to</Trans> {messageCode} <Trans>for free, To cancel from the site please go to</Trans> 
                  <Link to="/unsubscribe" className="decilimer"> <Trans>here</Trans></Link>
                </p>

              </div>
              
              
                <button
                  className="btn gamezroidverify 2"
                  disabled={verifyCode ? "" : "disabled"}
                  onClick={(e) => {
                    verify(e);
                  }} >
                  {t("Verify Subscribtion")}
                </button>
                <br></br>
                <button
                  className="btn resend gamezroidverify 3"

                  onClick={(e) => {
                    resend(e);
                  }} >
                  {t("Resend PIN code")}
                </button>

              </div> : null
            }


          </div>

          <div>

          </div>





          <Modal
            visible={visablemodalSubscribe}
            width="450"
            height="150"
            effect="fadeInUp"
            padding="0"
            onClickAway={() => closeModalSubscribe()}
          >
            <div className="subscribemodal">
              <h3>
                <Trans>Already subscribed</Trans>
              </h3>
              <p><Trans>You want to send data</Trans> ?</p>
              <button
                className="btn"
                onClick={(e) => {
                  sendData(e);
                }} >
                {t("Send")}
              </button>
            </div>


          </Modal>


          <Modal
            visible={visablemodalverify}
            width="450"
            height="150"
            effect="fadeInUp"
            padding="0"
            onClickAway={() => closeModalverify()}
          >
            <div className="verifymodal">
              <h3>
                <Trans>Successful Subscribtion</Trans>
              </h3>
              <div className="congrut"></div>
            </div>


          </Modal>



        </div>

      </div>
    </MauritaniaSignupPageDisplay>
  );

}
export default withTranslation()(MauritaniaSignupPage)
