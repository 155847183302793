import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
//import "./SignupPage.styled";
import "./BahrainVerifyPage.scss";

import BahrainVerifyPageDisplay from "./BahrainVerifyPage.styled";
import { setLoginData } from "../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router";
import { saveTracker } from "../../helpers/agencyTracker";

import { client } from "../../auth/Client";




const randomBg = bgSliders.randomBgooredoo();
function BahrainVerifyPage({ t }) {
  const [isUserSubscribed, setisUserSubscribed] = useState(false);
  const [isErrorExist, setisErrorExist] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const location = useLocation();
  const history = useHistory();

  //const useParams = useParams();
 
    // save tracker
    const tracker = async (GMSISDN) => {
      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = 'ZijMZyHHxh';
  
  
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: GMSISDN
        };
        console.log(datag)
  
      }
      await saveTracker(datag);
  
    }
 
    async function login(code, operator,plan, PackageCode,sendtracker=false) {
      console.log(code , 'code')
      if (code) {
        var url = `http://www.api.playit.mobi/api/v2/bahrain/fetchSubDetails?code=${code}&operator=${operator}&plan=${plan}&planCode=${PackageCode}`;
      //  var url ="http://www.api.playit.mobi/api/v2/bahrain/fetchSubDetails?code=78650001&operator=stc&planCode=d"

      // /bahrainVerify?operator=zain&PackageCode=78490001&plan=d&code=A5915248f-9645-41fe-8ce1-e89d4a6fec48&message=OK&status=200 
        axios.get(url)
          .then(res => {
           console.log('res after sub' , res.data)
            if (res.data.status == true) {
              if (res.data.data.username && res.data.data.password) {
                if(sendtracker){
                  tracker(res.data.data.username);
                }
                const hasAccess = confirmLogin(res.data.data.username ,res.data.data.password);
                if (hasAccess) { 
                    console.log('login access done')
                    setLoginData(res.data.data.user_id, res.data.data.token, res.data.data.username, res.data.data.photo, res.data.data.operator_id, res.data.data.user_email, res.data.data.msisdn);
                    history.push(`/slogin/${res.data.data.user_id}`);
                
                  
                }
              }
            }
            else {
              seterrorMessage(res.data.data)
              setErrorMesgSubscribe(res.data.data)
              setisErrorExist(true);
              setErrorMesg(true);
            
              // seterrors(false)
              // seterrsub(true)
              // seterrsubMsg(res.data.data);
            }
           // setloading(false)
  
          })
          .catch(function (error) {
            console.log(error)
          })
          .finally(function () {
            console.log('complete');
          });
  
  
      }
      
    }
    async function confirmLogin(username,password) {

      const hasAccess = await client.login(username,password);
      return hasAccess;
    }
  

    
  useEffect(() => {
    const params = queryString.parse(window.location.search);
 
    var queryParams;
    console.log(params);
    if (params.status == "SUCCESS" || params.status == "200") {
      console.log("SUCCESS")
      var packageCode = location.pathname.split('/')[2];
      var userNamePhone = params.customerId;
      queryParams = window.location.search.split('?');
      queryParams = queryParams[1];
      console.log('queryParams' ,queryParams);
      //queryParams += "&PackageCode=" + packageCode;
      console.log(queryParams , 'task');
      // login(params.code,params.operator,params.PackageCode,true);

            // /bahrainVerify?operator=zain&PackageCode=78490001&plan=d&code=A5915248f-9645-41fe-8ce1-e89d4a6fec48&message=OK&status=200 

      
      login(params.code,params.operator,params.plan,params.PackageCode,true);

      // var url = ""
      // url = `http://www.api.playit.mobi/api/v2/egypt/subscribeUser?${queryParams}`;
      // axios.get(url)
      //   .then(res => {
      //     console.log(res);
      //     if (res.data.status == true) {
      //       tracker(userNamePhone);
      //       setisUserSubscribed(true);
      //       //make login
      //       login(params.code,params.operator,params.PackageCode,true);
      //    // history.push("/");
      //     }
      //     else
      //     {
      //       setisUserSubscribed(false);
      //     //history.push("/");
      //     }
      //   })
      //   .catch(function (error) {
      //     setErrorMesg(true)
      //   })
      //   .finally(function () {
      //     console.log('complete');
      //   });

        //login(params.code,params.operator,params.PackageCode,true);
     
    }
    else if ((params.status == "ERROR" || params.status != '200')) {
      if(params.error == 'USER_ALREADY_SUBSCRIBED'){
        console.log("ERROR")
        setisErrorExist(true);
        seterrorMessage(params.message);
                console.log(params.message);
                console.log(errorMesg);  
                login(params.code,params.operator,params.PackageCode,true);

      }else{
        console.log("ERROR")
        setisErrorExist(true);
        seterrorMessage(params.message);
                console.log(params.message);
                console.log(errorMesg);
   //             login(params.code,params.operator,params.PackageCode,false);
   queryParams = window.location.search.split('?');
   queryParams = queryParams[1];
   console.log('queryParams' ,queryParams);
   //queryParams += "&PackageCode=" + packageCode;
   login(params.code,params.operator,params.PackageCode,true);

  //  var url = ""
  //  url = `http://www.api.playit.mobi/api/v2/egypt/subscribeUser?${queryParams}`;
  //  axios.get(url)
  //    .then(res => {
  //      console.log(res);
  //      if (res.data.status == true) {
  //        tracker(userNamePhone);
  //        setisUserSubscribed(true);
          
  //        login(params.code,params.operator,params.PackageCode,true);

  //        //make login
  //     // history.push("/");
  //      }
  //      else
  //      {
  //        setisUserSubscribed(false);
  //      //history.push("/");
  //      }
  //    })
  //    .catch(function (error) {
  //      setErrorMesg(true)
  //    })
  //    .finally(function () {
  //      console.log('complete');
  //    });


  
      }
            
/*      var url2 = "";
      url2 = `http://www.api.playit.mobi/api/v1/getToken/${params.customerId}/1`;
      axios.get(url2)
        .then(res => {
          console.log(res.data.code);
          if (res.data.code == 200) {
            //make login
           history.push("/");

          }
          else {
            history.push("/login");

          }
          //console.log('mondia catch ');
        })

        .catch(function (error) {
          setErrorMesg(true)
        })
        .finally(function () {
          console.log('complete');
        });
*/

    }
    else if(params.code)
    {
      console.log("done")
      // setvalidcode(true)
      if(params.status == 200){
        login(params.code,params.operator,params.PackageCode,true);
      }else{
        login(params.code,params.operator,params.PackageCode,false);
      }
      
      
      
       
    }
    else {
      // queryParams = window.location.search.replace('/duverify', '');
      queryParams = window.location.pathname + window.location.search;
      queryParams = queryParams.replace('/egyverify', '');

      console.log("URL",queryParams );

      setisErrorExist(true);
      console.log(params.errorDesc)
      seterrorMessage(params.errorDesc);
    
    }

  }, []);
  
  


  return (
    <BahrainVerifyPageDisplay  bgImg={randomBg}>

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

  

      <div className='operatorSignup'>
        <div className="formsLayout formsLayoutjawal formsLayoutduverify">
        {/* <div className="header" className="header">
                <Link to="/">
                  <div className="logoo" />
                </Link>
              </div> */}
          {/* <div className="links-Section links-Sectionjawwalgamezorid links-SectionMondia">
          </div> */}

          <div className="Permission">
            {isErrorExist ?     
            <p>{errorMessage}</p>
            : 
            <p><Trans>Thanksubscribing</Trans></p>
            }
          </div>

       

          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}




        </div>
      </div>

    </BahrainVerifyPageDisplay>
  );
}
export default withTranslation()(BahrainVerifyPage)
