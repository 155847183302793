import axios from "axios";
import React, { useEffect, useState } from "react";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import { setLoginData, clearLoginData } from "../../helpers/helpers";
import "./ShortUrlLoginPage.scss";
import ShortUrlLoginPageDisplay from "./ShortUrlLoginPage.styled";
export default function ShortUrlLoginPage(props) {
  const loginID = props.match.params.id;

  const [alert, SetAlert] = useState("");
  function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }
  useEffect(() => {
    // clearLoginData()
    async function fetchIt() {
      try {
        var params = {};
        console.log(loginID);
        if (isNumber(loginID)) {
          console.log('number');
          params = {
            msisdn: loginID
          };
        } else {
          console.log('not number');
          params = {
            UOLID: loginID
          };
        }


        const res = await axios.post(
          "http://www.api.playit.mobi/api/v2/login",
          params
        );
        console.log("res", res.data)
        if (res.data.status === true) {
          if (res.data.user_id) {
            console.log("task")
            if(res.data.subscriptionContractId){
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn, res.data.subscriptionContractId);
            }else{
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            
            }
           
          }
        }

        setTimeout(() => {
          props.history.push("/");
          window.location.reload();
        }, 2000);


        // if (res.data) {
        //   const data = res.data;
        //   if (
        //     setLoginData(
        //       data.user_id,
        //       data.token,
        //       data.user_name,
        //       data.photo,
        //       data.operator_id,
        //       data.user_email,
        //       data.msisdn
        //     )
        //   ) {
        //   //  props.history.push("/");
        //   }
        // }
      } catch (error) {
        SetAlert("Something went wrong!");
        props.history.push("/");

      }
    }
    fetchIt();
  }, [loginID, props]);

  return (
    <ShortUrlLoginPageDisplay>
      {alert !== "" ? (
        <FloatingAlert type="error">{alert}</FloatingAlert>
      ) : (
        <>
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
          <p>Please wait, We're loggin you in...</p>
        </>
      )}
    </ShortUrlLoginPageDisplay>
  );
}
